import ProductCard from '@/components/cards/ProductCard/index.vue';
import SeriesCard from '@/components/cards/SeriesCard/index.vue';
import catalogPagination from '@/modules/catalog/components/catalog-pagination/index.vue';
import seriesPagination from '@/modules/catalog/components/series-pagination/index.vue';
import groupProduct from '@/components/group-product/index.vue';
import categoryFilter from './components/category-filter/index.vue';
import sortSelect from './components/sort-select/index.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { directive as onClickaway } from 'vue-clickaway';
import _ from 'lodash';

export default {
  name: 'catalog',
  directives: {
    onClickaway: onClickaway
  },
  components: {
    groupProduct,
    ProductCard,
    categoryFilter,
    sortSelect,
    SeriesCard,
    catalogPagination,
    seriesPagination
  },
  data() {
    return {
      loadingProducts: false,
      loadingFilters: false,
      products: [],
      filters: [],
      openFilter: false,
      storedCatalogType: null,
      paginator: {
        hasNextPage: false,
        page: 1,
        perPage: 3 * 4 // load 4 lines per 3 product
      },
      mockSeries: [
        {
          id: 1,
          slug: 'serum-dermo',
          image:
            'https://api.skinon.com.ua/storage/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D1%96%D0%B2/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%B5%D0%BD%D1%8C%D1%8E/Aqua%20Vital%20Revitalizing%20Cream%2050%20ml-01.png',
          title: 'Vitamin A',
          description: 'Антиоксидантна серія по догляду за шкірою'
        },
        {
          id: 2,
          slug: 'serum-dermo',
          image:
            'https://api.skinon.com.ua/storage/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D1%96%D0%B2/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%B5%D0%BD%D1%8C%D1%8E/Aqua%20Vital%20Revitalizing%20Cream%2050%20ml-01.png',
          title: 'Vitamin B',
          description: 'Антиоксидантна серія по догляду за шкірою'
        },
        {
          id: 3,
          slug: 'serum-dermo',
          image:
            'https://api.skinon.com.ua/storage/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D1%96%D0%B2/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%B5%D0%BD%D1%8C%D1%8E/Aqua%20Vital%20Revitalizing%20Cream%2050%20ml-01.png',
          title: 'Vitamin C',
          description: 'Антиоксидантна серія по догляду за шкірою'
        },
        {
          id: 4,
          slug: 'serum-dermo',
          image:
            'https://api.skinon.com.ua/storage/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D1%96%D0%B2/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%B5%D0%BD%D1%8C%D1%8E/Aqua%20Vital%20Revitalizing%20Cream%2050%20ml-01.png',
          title: 'Vitamin D',
          description: 'Антиоксидантна серія по догляду за шкірою'
        },
        {
          id: 5,
          slug: 'serum-dermo',
          image:
            'https://api.skinon.com.ua/storage/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D1%96%D0%B2/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%B5%D0%BD%D1%8C%D1%8E/Aqua%20Vital%20Revitalizing%20Cream%2050%20ml-01.png',
          title: 'Vitamin E',
          description: 'Антиоксидантна серія по догляду за шкірою'
        },
        {
          id: 6,
          slug: 'serum-dermo',
          image:
            'https://api.skinon.com.ua/storage/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D1%96%D0%B2/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%B5%D0%BD%D1%8C%D1%8E/Aqua%20Vital%20Revitalizing%20Cream%2050%20ml-01.png',
          title: 'Vitamin F',
          description: 'Антиоксидантна серія по догляду за шкірою'
        }
      ]
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (this.storedCatalogType !== this.catalogType) {
            this.products = [];
            this.storedCatalogType = this.catalogType;
          }
          if (!this.loadingProducts) {
            this.resetPage();
            this.loadProducts();
          }
        }
      }
    }
  },
  created() {
    this.loadFilters();
    // this.loadProducts();
    this.storedCatalogType = this.catalogType;
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList'
    }),
    productsList() {
      if (!this.isAuthenticated || !this.favourites) {
        return this.products;
      }

      const favourites = _.map(this.favourites, 'productId');
      const markAsSelected = (product) => {
        product.isFavorite = favourites.includes(product.id);
        return product;
      };

      if (this.catalogType === 'table') {
        return _.map(this.products, markAsSelected);
      }

      return this.products.map((productGroup) => {
        _.map(productGroup.products, markAsSelected);

        return productGroup;
      });
    },
    catalogType() {
      if (this.$route.query.catalog_type === 'groups') {
        return 'groups';
      }

      return 'table';
    }
  },
  methods: {
    ...mapActions({
      fetchProductsForCatalog: 'products/GET_PRODUCTS_FOR_CATALOG',
      fetchCatalogFilters: 'products/GET_CATALOG_FILTERS'
    }),
    ...mapMutations({}),
    handlePageChange(page) {
      console.log(page);
      // this.currentPage = page;
      // Можно выполнить навигацию, например:
      // this.$router.push(`/katalog/page/${page}`);
    },
    test2() {
      // console.log(1);
    },
    openFilters() {
      this.openFilter = !this.openFilter;
      if (this.openFilter) {
        document.getElementById('html').classList.add('hide');
      } else {
        document.getElementById('html').classList.remove('hide');
      }
    },
    openCategory(item) {
      // XOR
      return !!(item.isOpened ^ this.open);
    },
    changeSlideToggle() {
      this.open = !this.open;
    },
    resetFilter() {
      this.$router
        .push({
          name: 'products',
          query: {
            catalog_type: this.$route.query.catalog_type
          }
        })
        .catch(() => {});
    },
    away: function () {
      this.openFilter = !this.openFilter;
    },
    async resetPage() {
      if (this.$route.name === 'products-paginated' || this.$route.name === 'series-list-paginated') {
        this.paginator.page = Number(this.$route.params.page);
      } else {
        this.paginator.page = 1;
      }
      this.paginator.hasNextPage = false;
    },
    async loadFilters() {
      // this.loadingFilters = true

      this.filters = await this.fetchCatalogFilters();

      // this.loadingFilters = false
    },
    async loadProducts() {
      this.loadingProducts = true;

      let urlQuery = cloneDeep(this.$route.query);

      if (this.paginator.page === 1) {
        this.products = [];
      }

      /** @type {ICatalogFilter} */
      const catalogFilters = {
        catalog_type: urlQuery.catalog_type,
        sort_filter_type: urlQuery.sort_filter_type || 'bestsellers',
        brands: urlQuery.brands,
        series: urlQuery.series,
        categories: urlQuery.categories,
        page: this.paginator.page,
        perPage: this.paginator.perPage
      };
      const catalogData = await this.fetchProductsForCatalog(catalogFilters);

      this.products.push(...catalogData.data);
      this.paginator.hasNextPage = !!catalogData.links.next;
      this.paginator.total = catalogData.meta.total;
      this.paginator.lastPage = catalogData.meta.lastPage;

      this.loadingProducts = false;
    },
    async paginateProducts() {
      this.paginator.page += 1;

      await this.loadProducts();
    }
  }
};
